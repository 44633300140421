<template>
  <v-select
    :value="value"
    :items="items"
    :name="label"
    :label="label"
    :disabled="disabled"
    :placeholder="placeholder"
    outlined
    :clearable="clearable"
    :multiple="multiple"
    :item-text="itemText"
    :item-value="itemValue"
    @input="input($event)"
    @blur="blur"
    :required="required"
    :return-object="returnObject"
    :error-messages="valueErrors"
    :class="customClass"
  />
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "TSelect",
  props: {
    value: {
      type: [String, Number, Array],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      required: false,
      default: false,
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    customClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  mixins: [validationMixin],
  methods: {
    input(e) {
      this.$emit("input", e);
      this.$v.value.$touch();
    },
    blur() {
      this.$v.value.$touch();
    },
  },
  validations() {
    return {
      value: {
        required: this.required ? required : false,
      },
    };
  },
  computed: {
    valueErrors() {
      const errors = [];
      if (!this.$v.value.$dirty) return errors;
      !this.$v.value.required && errors.push(`${this.label} is required`);
      return errors;
    },
  },
};
</script>

<style></style>
